/* eslint-disable */
import { isWeChat, isWxWork, isBrowser } from './utils'
import { v4 as uuidv4 } from 'uuid'

let sa
let abtest

// 因为 SDK 中，在加载的时候有使用 window 对象，gatsby 编辑阶段是在 node 环境
// 不存在 window，所以需要判断当前是否在浏览器环境
if (isBrowser()) {
  sa = require('./sensorsdata-sat.es6.min').default
  abtest = require('./abtest.esm.min').default
  require('./webPopup.esm.min')
  require('./popup.esm.min')
}

export let _cookie = {
  getItem: function (sKey) {
    return (
      decodeURIComponent(
        document.cookie.replace(
          new RegExp(
            '(?:(?:^|.*;)\\s*' + encodeURIComponent(sKey).replace(/[-.+*]/g, '\\$&') + '\\s*\\=\\s*([^;]*).*$)|^.*$',
          ),
          '$1',
        ),
      ) || null
    )
  },
  setItem: function (sKey, sValue, vEnd, sPath, sDomain, bSecure) {
    if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) {
      return false
    }
    var sExpires = ''
    if (vEnd) {
      switch (vEnd.constructor) {
        case Number:
          sExpires = vEnd === Infinity ? '; expires=Fri, 31 Dec 9999 23:59:59 GMT' : '; max-age=' + vEnd
          break
        case String:
          sExpires = '; expires=' + vEnd
          break
        case Date:
          sExpires = '; expires=' + vEnd.toUTCString()
          break
      }
    }
    document.cookie =
      encodeURIComponent(sKey) +
      '=' +
      encodeURIComponent(sValue) +
      sExpires +
      (sDomain ? '; domain=' + sDomain : '') +
      (sPath ? '; path=' + sPath : '') +
      (bSecure ? '; secure' : '')
    return true
  },
  removeItem: function (sKey, sPath, sDomain) {
    if (!sKey || !this.hasItem(sKey)) {
      return false
    }
    document.cookie =
      encodeURIComponent(sKey) +
      '=; expires=Thu, 01 Jan 1970 00:00:00 GMT' +
      (sDomain ? '; domain=' + sDomain : '') +
      (sPath ? '; path=' + sPath : '')
    return true
  },
  hasItem: function (sKey) {
    return new RegExp('(?:^|;\\s*)' + encodeURIComponent(sKey).replace(/[-.+*]/g, '\\$&') + '\\s*\\=').test(
      document.cookie,
    )
  },
  keys: /* optional method: you can safely remove it! */ function () {
    var aKeys = document.cookie
      .replace(/((?:^|\s*;)[^\=]+)(?=;|$)|^\s*|\s*(?:\=[^;]*)?(?:\1|$)/g, '')
      .split(/\s*(?:\=[^;]*)?;\s*/)
    for (var nIdx = 0; nIdx < aKeys.length; nIdx++) {
      aKeys[nIdx] = decodeURIComponent(aKeys[nIdx])
    }
    return aKeys
  },
}

// 为了防止 SDK 报错，导致页面崩溃，增加 trycatch 捕获 SDK 报错信息
/* 初始化 */
export const saInit = () => {
  try {
    if (isBrowser) {
      let protocol = window.location.protocol || 'https:'
      sa?.init({
        show_log: process.env.GATSBY_ACTIVE_ENV !== 'production',
        server_url: process.env.SA_SERVER_URL,
        is_track_single_page: false, // 单页面配置，默认开启，若页面中有锚点设计，需要将该配置删除，否则触发锚点会多触发 $pageview 事件
        use_client_time: true,
        send_type: 'beacon',
        heatmap: {
          //是否开启点击图，default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭。
          clickmap: 'default',
          //是否开启触达注意力图，not_collect 表示关闭，不会自动采集 $WebStay 事件，可以设置 'default' 表示开启。
          scroll_notice_map: 'not_collect',
        },
        preset_properties: { search_keyword_baidu: true }, // 开启百度关键词辅助采集功能
      })
      sa?.use('SensorsABTest', {
        url:
          protocol +
          '//abtest-tx-beijing-01.saas.sensorsdata.cn/api/v2/abtest/online/results?project-key=E8F42894D788E51EDCD7530B7D32EB8130E3BD06',
      })
      window['sa'] = sa
    }
  } catch (error) {
    console.log(error.message)
  }
}

/* 启动全埋点 */
export const saAutoTrack = () => {
  try {
    if (isBrowser) {
      var customAttrObj = {
        which_sd_platform: '简体官网',
      } // 自定义属性
      var _uq_ = JSON.parse(_cookie.getItem('_uq_') || '[]')

      if (_uq_.length) {
        _uq_.forEach(function (el, i) {
          if (i === 0) {
            customAttrObj.page_title = el
          } else if (i === 1) {
            customAttrObj.forward_first_title = el
          } else if (i === 2) {
            customAttrObj.forward_second_title = el
          } else if (i === 3) {
            customAttrObj.forward_third_title = el
          }
        })
      }

      // 微信环境初始化完成
      function onWeixinJSBridgeReady() {
        if (window['__wxjs_environment'] === 'miniprogram') {
          customAttrObj['page_open_environment'] = '微信小程序'
        }
        sa?.quick('autoTrack', customAttrObj)
      }

      if (isWeChat) {
        // 微信环境中
        customAttrObj['page_open_environment'] = '微信'
        if (isWxWork) {
          // 企业微信中
          customAttrObj['page_open_environment'] = '企业微信'
        }

        if (!window['WeixinJSBridge'] || !window['WeixinJSBridge']?.invoke) {
          document.addEventListener('WeixinJSBridgeReady', onWeixinJSBridgeReady, false)
        } else {
          onWeixinJSBridgeReady()
        }
      } else {
        // 其他浏览器
        customAttrObj['page_open_environment'] = '浏览器'
        sa?.quick('autoTrack', customAttrObj)
      }
    }
  } catch (error) {
    console.log(error.message)
  }
}

/**
 *
 * 角色   和目的： 这个方法是中间层，主要是把每个页面的 meta name='sa-title' 的内容存入cookie中。
 *
 * 上流：是每个页面的js文件，处理meta标签。
 *
 * 下流：获取cookie中的数据，进行上报。
 */
export function saCustom() {
  var domain = process.env.NODE_ENV === 'development' ? 'localhost' : '.sensorsdata.cn'

  // 预先处理支持 $pageview 这个事件要使用到的自定义属性
  function doPageview() {
    // urlQueue[0]： 刚进入页面时，代表最近一次访问的页面。  unshift 后，代表是当前页面。所以，在之后埋点的上报数据的时候，要上报 前一个页面的时候，要用 urlQueue[1]; 以此类推

    var urlQueue = JSON.parse(_cookie.getItem('_uq_') || '[]')
    var curPageTitle = (document.getElementsByTagName('meta')['sa-title'] || {}).content
    if (!curPageTitle) {
      // 个别页面是没有设置 sa-title 的。这个时候就要取默认的title。
      curPageTitle = document.title
    }
    if (curPageTitle && curPageTitle !== urlQueue[0]) {
      urlQueue.unshift(curPageTitle)
      urlQueue.length > 4 && (urlQueue.length = 4)
      _cookie.setItem('_uq_', JSON.stringify(urlQueue), false, '/', domain)
    }
  }

  doPageview()
}
/**
 *
 * 英文专属
 */

export function saCustomEn() {
  var domain = process.env.NODE_ENV === 'development' ? 'localhost' : '.sensorsdata.com'

  // 预先处理支持 $pageview 这个事件要使用到的自定义属性
  function doPageview() {
    // urlQueue[0]： 刚进入页面时，代表最近一次访问的页面。  unshift 后，代表是当前页面。所以，在之后埋点的上报数据的时候，要上报 前一个页面的时候，要用 urlQueue[1]; 以此类推

    var urlQueue = JSON.parse(_cookie.getItem('_uq_') || '[]')
    var curPageTitle = (document.getElementsByTagName('meta')['sa-title'] || {}).content
    if (!curPageTitle) {
      // 个别页面是没有设置 sa-title 的。这个时候就要取默认的title。
      curPageTitle = document.title
    }
    if (curPageTitle && curPageTitle !== urlQueue[0]) {
      urlQueue.unshift(curPageTitle)
      urlQueue.length > 4 && (urlQueue.length = 4)
      _cookie.setItem('_uq_', JSON.stringify(urlQueue), false, '/', domain)
    }
  }
  doPageview()
}

// 上报埋点
export let saTrack = (name, data) => {
  try {
    if (isBrowser) {
      sa?.track(name, data)
    }
  } catch (error) {
    console.log(error.message)
  }
}

// sa quick
export let saQuick = (name, fn) => {
  try {
    if (isBrowser) {
      sa?.quick(name, fn)
    }
  } catch (error) {
    console.log(error.message)
  }
}

// sa login
export let saLogin = (data) => {
  try {
    if (isBrowser) {
      sa?.login(data)
    }
  } catch (error) {
    console.log(error.message)
  }
}

// 触发abtest事件
export function fastFetchABTest({ param_name, default_value, value_type, callback }) {
  try {
    abtest?.fastFetchABTest({
      param_name,
      default_value,
      value_type,
      callback,
    })
  } catch (error) {
    console.log(error.message)
  }
}

// SF H5 弹窗监听
export function onH5Popup({ shouldStart, onStart, onFailed, onEnd }) {
  try {
    if (isBrowser) {
      sa?.use('Popup', {
        api_base_url: 'https://sensorswww.sfn-tx-beijing-01.saas.sensorsdata.cn/api/v2',
        popup_campaign_listener: {
          shouldStart,
          onStart,
          onFailed,
          onEnd,
        },
      })
    }
  } catch (error) {
    console.log(error.message)
  }
}

// SF WEB 弹窗监听
export function onWebPopup({ shouldStart, onStart, onFailed, onEnd }) {
  try {
    if (isBrowser) {
      sa?.use('WebPopup', {
        api_base_url: 'https://sensorswww.sfn-tx-beijing-01.saas.sensorsdata.cn/api/v2',
        popup_campaign_listener: {
          shouldStart,
          onStart,
          onFailed,
          onEnd,
        },
      })
    }
  } catch (error) {
    console.log(error.message)
  }
}

// 获取预制属性
export const saGetPresetProperties = (propertyName) => {
  return new Promise((resolve, reject) => {
    try {
      if (isBrowser) {
        sa?.quick('isReady', function () {
          resolve(sa?.getPresetProperties()[propertyName])
        })
      }
    } catch (error) {
      console.log(error)
      reject(error)
    }
  })
}

// 2021 驱动大会按钮点击事件
export const saTrackDs2021ButtonClick = (buttonName) => {
  saTrack('gw_qddh_buttonclick', {
    button_name: buttonName,
  })
}

// 2022 驱动大会按钮埋点事件
export const saTrackDs2022ButtonClick = (buttonName) => {
  saTrack('gw_qddh_buttonclick', {
    button_name: buttonName,
    qddh_year: 2022,
  })
}

//2022 驱动大会 线上demo详情页按钮埋点事件
export const saTrackOnlineDemoButtonClick = (buttonName) => {
  saTrack('Democenter_button_click', {
    button_name: buttonName,
  })
}

//2022驱动大会线上demo详情用户注册埋点事件
export const saTrackOnlineDemoLoginClick = (title) => {
  saTrack('AddAccount', {
    forward_first_title: title,
    requestBtn: '线上展区',
  })
}
/*  */

// 售后页面注册埋点|产品聚合页
export const saTrackSupportLoginClick = (title) => {
  saTrack('AddAccount', {
    forward_first_title: title,
  })
}

export const saTrackSolutionsOverviewLoginClick = () => {
  saTrack('AddAccount', {
    forward_first_title: '首页-行业解决方案-总览',
  })
}

// Forrester 报告登录页
export const saTrackForresterLoginClick = (val) => {
  saTrack('Read_report', {
    is_new_phone: val,
  })
}

// 英文登陆注册
export const saTrackLoginAndSignup = (name, data) => {
  saTrack(name, data)
}
// 设置用户属性
export const saSetUserStats = (data) => {
  sa?.setProfile(data)
}

/* eslint-disable no-new */
/*  */
